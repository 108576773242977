import { template as template_f230fec235f84fa68f69a35fe73a0a3b } from "@ember/template-compiler";
const SidebarSectionMessage = template_f230fec235f84fa68f69a35fe73a0a3b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
