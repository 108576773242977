import { template as template_d298971a6e384e9699161336d696e0c1 } from "@ember/template-compiler";
const WelcomeHeader = template_d298971a6e384e9699161336d696e0c1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
