import { template as template_724aafd4d8084e8196c8bd497151234d } from "@ember/template-compiler";
const FKControlMenuContainer = template_724aafd4d8084e8196c8bd497151234d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
