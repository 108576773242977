import { template as template_a64a47f3477c4975a0de6c9fc6f0f30c } from "@ember/template-compiler";
const FKLabel = template_a64a47f3477c4975a0de6c9fc6f0f30c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
